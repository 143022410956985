/* @charset 'UTF-8'; */
/* Slider */
.slick-loading .slick-list {
    /*background: #fff url('./ajax-loader.gif') center center no-repeat;*/
}

/*!* Icons *!*/
/*@font-face*/
/*{*/
/*    font-family: 'slick';*/
/*    font-weight: normal;*/
/*    font-style: normal;*/

/*    !*src: url('./fonts/slick.eot');*!*/
/*    !*src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');*!*/
/*}*/
/*!* Arrows *!*/
/*.slick-prev,*/
/*.slick-next*/
/*{*/
/*    font-size: 0;*/
/*    line-height: 0;*/

/*    position: absolute;*/
/*    top: 50%;*/

/*    display: block;*/

/*    width: 20px;*/
/*    height: 20px;*/
/*    padding: 0;*/
/*    -webkit-transform: translate(0, -50%);*/
/*    -ms-transform: translate(0, -50%);*/
/*    transform: translate(0, -50%);*/

/*    cursor: pointer;*/

/*    color: transparent;*/
/*    border: none;*/
/*    outline: none;*/
/*    background: transparent;*/
/*}*/
/*.slick-prev:hover,*/
/*.slick-prev:focus,*/
/*.slick-next:hover,*/
/*.slick-next:focus*/
/*{*/
/*    color: transparent;*/
/*    outline: none;*/
/*    background: transparent;*/
/*}*/
/*.slick-prev:hover:before,*/
/*.slick-prev:focus:before,*/
/*.slick-next:hover:before,*/
/*.slick-next:focus:before*/
/*{*/
/*    opacity: 1;*/
/*}*/
/*.slick-prev.slick-disabled:before,*/
/*.slick-next.slick-disabled:before*/
/*{*/
/*    opacity: .25;*/
/*}*/

/*.slick-prev:before,*/
/*.slick-next:before*/
/*{*/
/*    font-family: 'slick';*/
/*    font-size: 20px;*/
/*    line-height: 1;*/

/*    opacity: .75;*/
/*    color: white;*/

/*    -webkit-font-smoothing: antialiased;*/
/*    -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*.slick-prev*/
/*{*/
/*    left: -25px;*/
/*}*/
/*[dir='rtl'] .slick-prev*/
/*{*/
/*    right: -25px;*/
/*    left: auto;*/
/*}*/
/*.slick-prev:before*/
/*{*/
/*    content: '←';*/
/*}*/
/*[dir='rtl'] .slick-prev:before*/
/*{*/
/*    content: '→';*/
/*}*/

/*.slick-next*/
/*{*/
/*    right: -25px;*/
/*}*/
/*[dir='rtl'] .slick-next*/
/*{*/
/*    right: auto;*/
/*    left: -25px;*/
/*}*/
/*.slick-next:before*/
/*{*/
/*    content: '→';*/
/*}*/
/*[dir='rtl'] .slick-next:before*/
/*{*/
/*    content: '←';*/
/*}*/

/*!* Dots *!*/
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-size: 30px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: 0.25;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: white;
}
